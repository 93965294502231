<template>
  <!-- <head-top> -->
  <div class="payDetails" ref="payDetails">
    <div
      class="yi-pay"
      :class="{
        successful: list.status == 4,
        cancel: list.status == 5,
        determined: list.status == 1 || list.status == 2 || list.status == 3,
      }"
    >
      <i
        :class="{
          'item-icom-two':
            list.status == 1 || list.status == 2 || list.status == 3,
          'item-icom-one': list.status == 4,
          'item-icom-three': list.status == 5,
        }"
      ></i>
      <span>{{
        list.status == 1 || list.status == 2
          ? "待支付"
          : list.status == 3
          ? "支付失败"
          : list.status == 4
          ? "支付成功"
          : "已取消"
      }}</span>
      <div class="back-arrow" @click="clickBack()"></div>
    </div>
    <div class="payDetails-trip">
      <div class="title">{{ list.hotelName }}</div>
      <div class="payDetails-contet">
        <div class="left">行程</div>
        <div class="middle">
          <span class="yuan top"></span>
          <span class="xian"></span>
          <span class="yuan bottom"></span>
          <div class="ruzhu">{{ list.checkTime }} 14点后入住</div>
          <div class="likai">{{ list.leaveTime }} 12点前离店</div>
        </div>
        <div class="right">共{{ list.days }}晚</div>
      </div>
      <div class="place" v-if="list.company">
        <div class="left">公司</div>
        <div class="middle">{{ list.company }}</div>
      </div>
      <div class="place" @click="clickActivity()">
        <div class="left">活动</div>
        <div class="middle">{{ list.activityName }}</div>
      </div>
      <!-- <div class="place">
        <div class="left">会议室</div>
        <div class="middle">{{ list.conferenceRoomName }}</div>
      </div> -->
    </div>

    <div class="facilitiesContent">
      <div class="div1">
        <span>入住人信息</span>
        <span style="float: right">共{{ list.roomPeoples.length }}间</span>
      </div>
      <div
        class="facilities"
        v-for="(item, index) in list.roomPeoples"
        :key="index"
      >
        <div class="item-content">
          <div class="item-type">房间{{ index + 1 }}</div>
          <div class="chuang">
            <div class="room">{{ item.type }}</div>
            <div class="specific">{{ item.remark }}</div>
          </div>
          <div class="content-right">
            <img src="../../assets/jiudian.png" alt="" />
          </div>
        </div>
        <div class="item-content">
          <div class="item-type">姓名</div>
          <div class="chuang">
            <div class="room">{{ item.name }}</div>
          </div>
          <div class="content-right">
            <img src="../../assets/touxiang.png" alt="" />
          </div>
        </div>
        <div class="item-content">
          <div class="item-type">手机</div>
          <div class="chuang">
            <div class="room">{{ item.phone }}</div>
          </div>
          <div class="content-right">
            <img src="../../assets/dianhua.png" alt="" />
          </div>
        </div>
             <div class="item-content">
          <div class="item-type">同住人</div>
          <div class="chuang">
            <div class="room">{{ item.roommate }}</div>
          </div>
          <div class="content-right">
            <img src="../../assets/touxiang.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="invoice">
      <div class="item">
        <span class="left">预订人</span>
        <span class="content">{{ list.name }}</span>
      </div>
      <div class="item">
        <span class="left">预订人号码</span>
        <span class="content">{{ list.phone }}</span>
      </div>
      <div class="item">
        <span class="left">订单号</span>
        <span class="content">{{ list.orderNo }}</span>
      </div>
      <div class="item">
        <span class="left">下单时间</span>
        <span class="content">{{ list.createTime }}</span>
      </div>
      <div class="item">
        <span class="left">支付时间</span>
        <span class="content">{{ list.payTime }}</span>
      </div>
      <div class="item">
        <span class="left">订单总额</span>
        <span class="content" style="color: red"
          >￥ {{ zhuanfen(list.sumPrice) }}</span
        >
      </div>
      <div v-if="list.status == 4 && list.isInvoiceType == 0">
        <div class="item">
          <span class="left">申请开票</span>
          <span class="content kaiguan"
            ><van-switch @change="changeSwitch" v-model="checked" size="24px"
          /></span>
        </div>
        <div class="invoice-content" v-if="checked">
          <div class="invoice-radio">
            <van-radio-group v-model="radio" direction="horizontal">
              <van-radio name="0">个人</van-radio>
              <van-radio name="1">企业</van-radio>
            </van-radio-group>
          </div>

          <div v-if="radio == 0">
            <div class="item">
              <van-field
                :placeholder="disabled ? '' : '请输入名称（必填）'"
                v-model="personalName"
                label="名称："
              />
            </div>
            <div class="item">
              <van-field
                :placeholder="disabled ? '' : '请输入电话号码（必填）'"
                v-model="list.invoicePhone"
                label="电话号码："
              />
            </div>
            <div class="item">
              <van-field
                :placeholder="disabled ? '' : '请输入发送邮箱（必填）'"
                v-model="list.invoiceEmail"
                label="发送邮箱："
              />
            </div>
          </div>
          <div v-else>
            <div class="item">
              <van-field
                :placeholder="disabled ? '' : '请输入发票抬头（必填）'"
                v-model="invoiceTitle"
                label="发票抬头："
              />
            </div>
            <div class="item">
              <van-field
                :placeholder="disabled ? '' : '请输入发票税号（必填）'"
                v-model="list.invoiceTfn"
                label="发票税号"
              />
            </div>
            <div class="item">
              <van-field
                :placeholder="disabled ? '' : '请输入单位地址（选填）'"
                v-model="list.invoiceAddress"
                label="注册地址："
              />
            </div>
            <div class="item">
              <van-field
                :placeholder="disabled ? '' : '请输入电话号码（选填）'"
                v-model="list.invoiceTel"
                label="注册电话："
              />
            </div>

            <div class="item">
              <van-field
                :placeholder="disabled ? '' : '请输入开户银行（选填）'"
                v-model="list.invoiceBank"
                label="开户银行："
              />
            </div>
            <div class="item">
              <van-field
                :placeholder="disabled ? '' : '请输入银行账户（选填）'"
                v-model="list.invoiceBankNo"
                label="银行账户："
              />
            </div>
            <div class="item">
              <van-field
                :placeholder="disabled ? '' : '请输入发送邮箱（必填）'"
                v-model="list.invoiceEmail"
                label="发送邮箱："
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="anniu"
        v-if="list.isInvoiceType == 0 && list.status == 4 && checked"
      >
        <button class="submit-amount" @click="applyInvoice">申请</button>
      </div>

      <div v-if="list.status == 4 && list.isInvoiceType != 0">
        <div class="item">
          <span class="left">发票信息</span>
        </div>
        <div class="invoice-content" v-if="list.invoiceType == 1">
          <div class="item">
            <div class="span-item">
              <span class="span-left">发票抬头：</span>
              <span class="span-right">{{ list.invoiceTitle }}</span>
            </div>
          </div>
          <div class="item">
            <div class="span-item">
              <span class="span-left">发票税号：</span>
              <span class="span-right">{{ list.invoiceTfn }}</span>
            </div>
          </div>
          <div class="item">
            <div class="span-item">
              <span class="span-left">注册地址：</span>
              <span class="span-right">{{ list.invoiceAddress }}</span>
            </div>
          </div>
          <div class="item">
            <div class="span-item">
              <span class="span-left">注册电话：</span>
              <span class="span-right">{{ list.invoicePhone }}</span>
            </div>
          </div>

          <div class="item">
            <div class="span-item">
              <span class="span-left">开户银行：</span>
              <span class="span-right">{{ list.invoiceBank }}</span>
            </div>
          </div>
          <div class="item">
            <div class="span-item">
              <span class="span-left">银行账户：</span>
              <span class="span-right">{{ list.invoiceBankNo }}</span>
            </div>
          </div>
          <div class="item">
            <div class="span-item">
              <span class="span-left">发送邮箱：</span>
              <span class="span-right">{{ list.invoiceEmail }}</span>
            </div>
          </div>
        </div>
        <div class="invoice-content" v-if="list.invoiceType == 0">
          <div class="item">
            <div class="span-item">
              <span class="span-left">名称：</span>
              <span class="span-right">{{ list.name }}</span>
            </div>
          </div>
          <div class="item">
            <div class="span-item">
              <span class="span-left">电话号码：</span>
              <span class="span-right">{{ list.invoicePhone }}</span>
            </div>
          </div>
          <div class="item">
            <div class="span-item">
              <span class="span-left">发送邮箱：</span>
              <span class="span-right">{{ list.invoiceEmail }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </head-top> -->
</template>

<script>
import headTop from "../../components/head.vue";
import { orderDetail, applyInvoice } from "../../api/home/index.js";
export default {
  components: {
    headTop,
  },
  data() {
    return {
      checked: false,
      value: "",
      radio: "1",
      list: "",
      disabled: false,
      invoiceTitle: "", //发票抬头
      personalName: "", //个人名称
      messageListTimer:"",
    };
  },
  created() {
    this.orderDetail();
  },
  mounted() {},
  methods: {
    // 跳转活动页面
    clickActivity(){
      this.$router.push({
         path: '/',
         query:{
           t: window.sessionStorage.getItem("token"),
           a:this.list.fakeActivityId,
         }
      })
    },
    changeSwitch(val) {
      console.log(val);
      if (val) {
        this.messageListTimer = setInterval(() => {
          window.scrollTo({
            top: document.documentElement.offsetHeight,
            behavior: "smooth",
          });
          clearInterval(this.messageListTimer);
        }, 200);

        // setInterval(() => {
        //   window.scrollTo({
        //     top: document.documentElement.offsetHeight,
        //     behavior: "smooth",
        //   });
        // }, 200);
      }
    },
    zhuanfen(roomPrice) {
      return (roomPrice / 100).toFixed(2);
    },
    // 申请发票
    async applyInvoice() {
      if (this.radio == "0") {
        if (!this.personalName) {
          this.$toast("请填写名称");
          return;
        }
      }
      if (this.radio == "1") {
        if (!this.invoiceTitle) {
          this.$toast("请填写发票抬头");
          return;
        }
        if (!this.list.invoiceTfn) {
          this.$toast("请填写发票税号");
          return;
        }
      }
      let data = {
        invoiceTitle: this.radio == 1 ? this.invoiceTitle : this.personalName, //发票名称
        orderId: this.$route.query.orderId, //订单id
        invoiceType: this.radio, //发票类型 枚举值 0个人 1单位
        invoicePhone: this.list.invoicePhone, //发票个人手机
        invoiceEmail: this.list.invoiceEmail, //发送邮箱
        invoiceTfn: this.list.invoiceTfn, //发票税码
        invoiceAddress: this.list.invoiceAddress, //发票地址
        invoiceTel: this.list.invoiceTel, //单位电话
        invoiceBank: this.list.invoiceBank, //开户银行
        invoiceBankNo: this.list.invoiceBankNo, //发票单位银行账户
      };
      let res = await applyInvoice(data);
      if (res.code == 200) {
        this.$toast.success("申请成功");
        this.$router.push({
          name: "payList",
        });
      }
    },
    clickBack() {
      this.$router.go(-1);
    },
    async orderDetail() {
      let data = {
        orderId: this.$route.query.orderId,
      };
      let res = await orderDetail(data);
      if (res.code == 200) {
        this.list = res.data;
        this.personalName = JSON.parse(JSON.stringify(this.list.name));
        console.log(this.list, " this.list");
        this.disabled = this.list.isInvoiceType == 0 ? false : true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.payDetails {
  .yi-pay {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .item-icom-one {
      display: inline-block;
      width: 42px;
      height: 42px;
      background-image: url("../../assets/already-pay.png");
      background-size: cover;
      vertical-align: middle;
      margin-right: 15px;
    }
    .item-icom-two {
      display: inline-block;
      width: 42px;
      height: 42px;
      background-image: url("../../assets/stay-pay.png");
      background-size: cover;
      vertical-align: middle;
      margin-right: 15px;
    }
    .item-icom-three {
      display: inline-block;
      width: 42px;
      height: 42px;
      background-image: url("../../assets/cancel-pay.png");
      background-size: cover;
      vertical-align: middle;
      margin-right: 15px;
    }
    span {
      font-size: 42px;
      color: #000;
    }
    .back-arrow {
      position: absolute;
      top: 20px;
      left: 0;
      height: 40px;
      width: 90px;
      display: flex;
      // align-items: center;
      justify-content: center;
      &:before {
        content: "";
        width: 24px;
        height: 24px;
        border-bottom: 1px solid #333; /*no*/
        border-left: 1px solid #333; /*no*/
        transform-origin: center;
        transform: rotateZ(45deg);
        margin-left: -10px;
      }
    }
  }
  .successful {
    background: rgb(221, 255, 151);
  }
  .determined {
    background: yellow;
  }
  .cancel {
    background: #f1f1f1;
  }
  .payDetails-trip {
    background: #fff;
    padding: 20px;
    .title {
      text-align: center;
      font-size: 44px;
      font-weight: 600;
    }
    .payDetails-contet {
      margin-top: 50px;
      margin-bottom: 50px;
      height: 180px;
      display: flex;
      .left {
        height: 100%;
        font-size: 28px;
        width: 100px;
        line-height: 180px;
        padding-left: 20px;
      }
      .middle {
        height: 100%;
        flex: 1;
        position: relative;
        .yuan {
          width: 25px;
          height: 25px;
          background: rgba(66, 155, 255, 1);
          display: inline-block;
          border-radius: 50%;
        }
        .top {
          position: absolute;
          top: 10px;
        }
        .bottom {
          position: absolute;
          bottom: 0;
        }
        .xian {
          height: 132px;
          width: 5px;
          background: rgba(66, 155, 255, 1);
          display: inline-block;
          position: absolute;
          top: 24px;
          left: 9px;
        }
        .ruzhu {
          font-size: 36px;
          text-align: center;
        }
        .likai {
          font-size: 36px;
          text-align: center;
          margin-top: 92px;
        }
      }
      .right {
        height: 100%;
        font-size: 28px;
        width: 100px;
        line-height: 180px;
      }
    }
    .place {
      display: flex;
      padding: 20px;
      .left {
        height: 100%;
        font-size: 28px;
        width: 160px;
      }
      .middle {
        flex: 1;
        font-size: 28px;
      }
    }
  }
  .facilitiesContent {
    margin-top: 15px;
    padding-top: 10px;
    background: #fff;

    .div1 {
      font-size: 28px;
      font-weight: 600;
      padding-left: 20px;
      height: 40px;
      line-height: 40px;
      padding: 20px;
      padding-left: 40px;
    }
    .facilities {
      border-top: 1px solid #ccc;
      // margin-top: 15px;
      width: 100%;
      background: #fff;
      // padding-left: 20px;
      .item-content {
        display: flex;
        padding: 8px 20px;
        padding-left: 40px;
        line-height: 1;
        position: relative;
        .item-type {
          width: 160px;
          font-size: 30px;
          line-height: 50px;
        }
        .chuang {
          // flex: 1;
          font-size: 30px;
          line-height: 50px;
          .room {
            font-size: 30px;
          }
          .specific {
            color: rgba(141, 141, 141, 1);
            font-size: 24px;
          }
        }
        .content-right {
          position: absolute;
          right: 40px;
          top: 0px;
          img {
            width: 30px;
            height: 30px;
            padding-top: 5px;
          }
        }
      }
    }
  }

  .invoice {
    margin-top: 15px;
    padding: 20px;
    background: #fff;
    font-size: 28px;
    .item {
      padding-left: 20px;
      display: flex;
      margin-bottom: 15px;
      line-height: 40px;
      .left {
        width: 160px;
      }
      .content {
        flex: 1;
      }
      .jine {
        color: rgba(212, 48, 48, 1);
      }
      .kaiguan {
        text-align: right;
      }
    }
    .invoice-content {
      border: 1px solid rgba(204, 204, 204, 1);
      padding: 20px 0;
      .invoice-radio {
        text-align: center;
        margin-bottom: 20px;
        padding-left: 200px;
        /deep/.van-radio--horizontal {
          margin-right: 30px;
        }
      }
      .item {
        display: flex;
        font-size: 28px;
        .span-item {
          padding: 0.266666rem 0.426666rem;
          .span-left {
            display: inline-block;
            width: 6.2em;
            font-size: 28px;
            color: #333;
          }
          .span-right {
            color: #333;
          }
        }
      }
    }
    .anniu {
      text-align: center;
      margin-top: 15px;
    }
  }
  .submit-amount {
    height: 70px;
    width: 180px;
    display: inline-block;
    background-color: rgba(66, 155, 255, 1);
    color: #fff;
    line-height: 70px;
    text-align: center;
    font-size: 28px;
    border-radius: 60px;
    border: none;
    margin: 0 auto;
  }
}
</style>